import Vue from 'vue'
import App from './App'
import router from './router'
import http from './http/request'
import store from './store'
import plugins from './plugins'
import VueParticles from 'vue-particles'
import packages from './package'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

Vue.prototype.$http = http
Vue.prototype.$eventBus = new Vue()
Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(plugins)
Vue.use(VueParticles)
Vue.use(packages)

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  components: { App },
  template: '<App/>'
})
