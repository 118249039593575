<template>
    <div class="nav">
      <div class="nav-content">
        <div class="user">
          <div class="avatarurl">
            <img src="https://lanzhiyuan99.cn/image/chuyin2.jpeg" />
          </div>
          <div class="user-title">
            <div class="user-hello">哈喽</div>
            <div class="user-name">爱吃土豆的挖掘机</div>
          </div>
        </div>
        <div class="nav-list">
          <div
            class="nav-item"
            :class="[getRoutePath === item.path?'is-border':'']"
            v-for="item in navList"
            :key="item.name"
            @click="changeNav(item.path)"
          >
            <div class="nav-item-name">{{item.name}}</div>
          </div>
        </div>
        <div class="mobile-menu">
          <i class="iconfont icon-caidan" v-checkClick="showMenu"></i>
        </div>
        <div class="mobile-nav" v-if="isShowMenu">
          <ul class="mobile-nav-list">
            <li
              class="mobile-nav-list-item"
              :class="[getRoutePath === item.path?'is-mobile-border':'']"
              v-for="item in navList"
              :key="item.name"
              @click="changeNav(item.path)"
            >
              <div class="mobile-nav-name">{{item.name}}</div>
            </li>
          </ul>
        </div>
      </div>
    </div>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    name: "Nav",
    data () {
      return {
        isShowMenu : false
      }
    },
    computed : {
      ...mapState(['navList']),
      getRoutePath () {
        return this.$route.path
      }
    },
    methods : {
      changeNav (p) {
        if (this.isShowMenu) {
          this.isShowMenu = false
        }
        if (p === this.$route.path) {
          return
        }
        this.$router.push(p)
      },
      showMenu () {
        this.isShowMenu = !this.isShowMenu
      }
    },
    watch : {
      isShowMenu (newValue) {
        newValue ? this.$showMask() : this.$closeMask()
      }
    }
  }
</script>

<style scoped>
  .mobile-menu {
    display: none;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  .mobile-nav {
    position: fixed;
    top: 72px;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1000;
  }
  .mobile-nav-list-item {
    height: 45px;
    line-height: 45px;
    padding-left: 10px;
    border-bottom: .5px solid #525252;
  }
  .mobile-nav-list-item.is-mobile-border{
    border-left: 4px solid #ff6ea5;
  }
  .mobile-nav-name {
    color: #fff;
    font-size: 16px;
  }
  .icon-caidan {
    font-size: 34px;
    color: #fff;
  }
  .nav {
    width: 100%;
    background: #333;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    height: 72px;
  }
  .nav-content {
    position: relative;
    width: 90%;
    height: 100%;
    margin: 0 auto;
    display: flex;
  }
  .user {
    height: 100%;
    display: flex;
  }
  .user-title {
    font-size: 16px;
    color: #fff;
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .avatarurl {
    width: 72px;
    height: 100%;
    display: flex;
    align-items: center;
  }
  .avatarurl img {
    display: block;
    width: 60px;
    height: 60px;
    border-radius: 6px;
  }
  .nav-list {
    margin-left: 72px;
    display: flex;
  }
  .nav-item {
    padding: 0 22px;
    font-size: 14px;
    color: #fff;
  }
  .nav-item.is-border {
    border-bottom: 4px solid #ff6ea5;
  }
  .nav-item-name {
    height: 68px;
    line-height: 68px;
  }
  @media (max-width: 768px) {
    .nav-list {
      display: none;
    }
    .mobile-menu {
      display: block;
    }
  }
</style>
