import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
export default new Vuex.Store({
    state : {
      navList : [
        {
          name : '首页',
          path : '/index'
        },
        {
          name : 'TA的作品',
          path : '/project'
        }
      ],
      cachePage : []
    },
    mutations : {
      addCachePage : (state,name) => {
        if (state.cachePage.indexOf(name) < 0) {
          state.cachePage.push(name)
        }
      }
    },
    actions : {

    }
})
