<template>
  <div class="footer-container">
    <div class="footer-content">
      <el-row>
        <el-col :xs="24" :span="6">
          <div class="grid-content bg-purple lan">
            <p class="title">兰~</p>
            <ul class="list">
              <router-link tag="li" to="/project">作品展示</router-link>
            </ul>
          </div>
        </el-col>
        <el-col :xs="24" :span="6">
          <div class="grid-content bg-purple lan">
            <p class="title">作品</p>
            <ul class="list">
              <li>
                <a href="httt://blog.lanzhiyuan99.cn" target="_blank">博客</a>
              </li>
            </ul>
          </div>
        </el-col>
        <el-col :xs="24" :span="6">
          <div class="grid-content bg-purple lan">
            <p class="title">TA的主页</p>
            <ul class="list">
              <router-link tag="li" to="/">爱吃土豆的挖掘机</router-link>
            </ul>
          </div>
        </el-col>
        <el-col :xs="24" :span="6">
          <div class="grid-content bg-purple lan">
            <p class="title">联系方式</p>
            <ul class="list">
              <li>lanzhiyuan99@126.com</li>
            </ul>
          </div>
        </el-col>
      </el-row>
      <div class="keep-on-record">
        <a href="https://beian.miit.gov.cn" target="_blank"
          >京ICP备2023029335号</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped lang="scss">
.footer-container {
  background: #333;
  min-height: 100px;
  padding-top: 40px;
  .footer-content {
    width: 70%;
    margin: 0 auto;
    color: #fff;
  }
}
.lan {
  .title {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 12px;
  }
  .list {
    font-size: 12px;
    li {
      margin-bottom: 8px;
      opacity: 0.8;
      a {
        color: #fff;
      }
    }
  }
}
.keep-on-record {
  text-align: center;
  margin-top: 40px;
  padding-bottom: 10px;
  a {
    color: #fff;
    opacity: 0.8;
    font-size: 12px;
  }
}
@media (max-width: 768px) {
  .lan {
    border-bottom: 1px solid hsla(0, 0%, 80.8%, 0.24);
    margin-bottom: 30px;
  }
}
</style>
