<template>
  <div id="app">
    <vue-particles
      color="#dedede"
      :particleOpacity="0.7"
      :particlesNumber="60"
      shapeType="circle"
      :particleSize="4"
      linesColor="#dedede"
      :linesWidth="1"
      :lineLinked="true"
      :lineOpacity="0.4"
      :linesDistance="150"
      :moveSpeed="2"
      :hoverEffect="true"
      hoverMode="grab"
      :clickEffect="true"
      clickMode="push"
      class="lizi"
    >
    </vue-particles>
    <div class="model">
      <live2d
        :style="getStyle"
        :model="[1, 53]"
        direction="right"
        :size="255"
        homePage="https://lanzhiyuan99.cn"
      ></live2d>
    </div>
    <router-view />
    <div class="top" ref="top" @click="goTop">
      <i class="iconfont icon-huidaodingbu"></i>
    </div>
  </div>
</template>

<script>
import live2d from "vue-live2d";
export default {
  name: "App",
  data() {
    return {};
  },
  computed: {
    getStyle() {
      return {
        position: "fixed",
        right: "50px",
        bottom: "60px",
        "z-index": "10",
      };
    },
  },
  components: {
    live2d,
  },
  mounted() {
    window.addEventListener(
      "scroll",
      this.$tools.debounce(() => {
        if (this.getScroll() > 300) {
          this.$refs.top.style.display = "block";
        } else {
          this.$refs.top.style.display = "none";
        }
      }, 500)
    );
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("scroll");
    });
  },
  methods: {
    getScroll() {
      return (
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop
      );
    },
    goTop() {
      let timer = setInterval(() => {
        let speed = Math.floor(-this.getScroll() / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          this.getScroll() + speed;
        if (this.getScroll() === 0) {
          clearInterval(timer);
        }
      }, 16);
    },
  },
};
</script>

<style lang="scss">
body,
html {
  width: 100%;
  font-family: Microsoft YaHei, Microsoft YaHei UI;
  box-sizing: border-box;
  font-size: 22px;
  overflow-x: hidden;
  cursor: url("./assets/zhizhen.cur"), auto;
}
.top {
  display: none;
  position: fixed;
  right: 10px;
  bottom: 60px;
  width: 35px;
  height: 35px;
  z-index: 110;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  text-align: center;
  line-height: 35px;
  i {
    margin-left: 2px;
    font-size: 24px;
    color: #666;
  }
}
.lizi {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
.model.vue-live2d-tip {
  font-size: 20px;
}
.model.fa-info-circle {
  display: none !important;
}
@media (max-width: 865px) {
  .model {
    display: none;
  }
}
</style>
