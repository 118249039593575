import Vue from 'vue'
import Router from 'vue-router'
import Index from '../components/index'

Vue.use(Router)

const router = new Router({
  mode : 'history',
  routes: [
    {
      path: '/',
      name: 'index',
      redirect : '/index',
      component: Index,
      children : [
        {
          path : '/index',
          name : 'index',
          meta : {
            index : 1
          },
          component : () => import('../views/index.vue')
        },
        {
          path : '/project',
          name : 'project',
          meta : {
            index : 2
          },
          component : () => import('../views/project.vue')
        }
      ]
    }
  ]
})

export default router

router.afterEach((to,from,next) => {
  window.scrollTo(0,0)
})
