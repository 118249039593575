<template>
    <div class="index">
      <div class="nav-container">
        <Nav></Nav>
      </div>
      <div :style="{minHeight : minHeight + 'px',width:'100%'}">
        <transition :name="transitionName">
          <keep-alive :include="cachePage">
            <router-view></router-view>
          </keep-alive>
        </transition>
      </div>
      <div class="footer-box">
        <Footer></Footer>
      </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import Nav from '../components/Nav'
    import Footer from '../components/Footer'
    export default {
      name: 'index',
      data () {
        return {
          minHeight: 1000,
          transitionName : '',
          isFoot : true
        }
      },
      components : {
        Nav,
        Footer
      },
      computed: {
        ...mapState(['cachePage']),
      },
      created () {
      },
      mounted () {
        this.getMinHeight()
        window.onresize = this.$tools.debounce(this.getMinHeight,500)
      },
      methods : {
        getMinHeight () {
          let footerHeight = document.querySelector('.footer-container').offsetHeight
          this.minHeight =  document.documentElement.clientHeight - 72 -footerHeight
        }
      },
      watch : {
        $route(to,from) {
          // if(to.meta.index > from.meta.index){
          //   //设置动画名称
          //   this.transitionName = 'slide-left';
          // }else{
          //   this.transitionName = 'slide-right';
          // }
        }
      }
    }
</script>

<style scoped lang="scss">
  .index {
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .nav-container {
    height: 72px;
  }
  .slide-right-enter-active,
  .slide-right-leave-active,
  .slide-left-enter-active,
  .slide-left-leave-active {
    will-change: transform;
    transition: all 500ms;
    position: fixed;
  }
  .slide-right-enter {
    opacity: 0;
    transform: translate3d(-70%, 0, 0);
  }
  .slide-right-leave-active {
    opacity: 0;
    transform: translate3d(70%, 0, 0);
  }
  .slide-left-enter {
    opacity: 0;
    transform: translate3d(70%, 0, 0);
  }
  .slide-left-leave-active {
    opacity: 0;
    transform: translate3d(-70%, 0, 0);
  }
</style>
